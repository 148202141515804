import React, { useState, useEffect } from 'react'
import { motion } from "framer-motion";

import './About.scss';
import { urlFor, client } from '../../client';

import { AppWrap } from '../../wrapper';
import { MotionWrap } from '../../wrapper'

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 0.8,
      ease: 'easeInOut',
    }
  }
}

const About = () => {

  const [about, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]'
    client.fetch(query)
      .then((data) => setAbouts(data))
  }, [])

  return (
    <>
    <h2 className="head-text">Providing</h2>
      <h2 className="head-text"><span>Better</span> & Safer <span>Cloud Solutions</span></h2>

      <div className='app__profiles'>
        {about.map((about, index) => (
          <motion.div
            variants={scaleVariants}
            whileInView={scaleVariants.whileInView}
            // whileHover={{scale: 1.1}}
            // transition={{ duration: 0.5, type: 'tween'}}
            className="app__profile-item"
            key={about.title + index}
            >
              <img src={urlFor(about.imgUrl)} alt={about.title} /> 
              <h2 className='bold-text' style={{marginTop: 20}}>{about.title}</h2>
              <p className='p-text' style={{marginTop: 10}}>{about.description}</p>
          </motion.div>
        ))}

      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg'
  );