import React from 'react'
import './Header.scss';
import { images } from '../../constants';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    }
  }
}

const Header = () => {
  return (
    <div className='app__header app__flex'>
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1]}}
        transition={{ duration: 1 }}
        className="app__header-info"
        >
          <div className='app__header-badge'>
            <div className='badge-cmp app__flex'>
              <span>👋</span>
              <div>
                  <p className='p-text'>Hello, I am</p>
                  <h1 className='head-text'>Zaryab</h1>
              </div>
            </div>
            <div className='tag-cmp app__flex'>
              <p>Founder and Chief Technology Officer at </p>
              <div className="digita-text">Digita Solutions</div>
            </div>
          </div>
      </motion.div>
      <motion.div
        whileInView={{opacity: [0, 1]}}
        transition={{ duration: 0.8, delayChildren: 0.8}}
        className="app__header-img"
        >
          <img src={images.profile} alt='profile_bg' />
          <motion.img
            whileInView={{scale: [0, 1]}}
            transition={{ duration: 0.8, ease: 'easeInOut'}}
            src={images.circle}
            alt="profile_circle"
            className='overlay_circle'
          />
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.flutter, images.redux, images.sass].map((circle, index) => (
          <div className='circle-cmp app__flex' key={`circle-${index}`}>
              <img src={circle} alt='circle' />
          </div>
        ))}
      </motion.div>

    </div>
  )
}

export default AppWrap(Header, 'home');