import React from 'react'
import { BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs"
import { FaFacebook } from "react-icons/fa"

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
            <BsInstagram/>
        </div>
        <div>
            <BsLinkedin/>
        </div>
    </div>
  )
}

export default SocialMedia